<template>
	<div class="data-content">
		<div class="top-box">
			<div>
				<button class="add-btn" @click="handleEditData('add','')" v-if="perms.includes('train:question:add')">新增</button>
				<button class="import-btn" @click="handleImport" v-if="perms.includes('train:question:import')">导入</button>
				<input style="display:none;" ref="uploadExcel" type="file" id="uploadExcel" @change="uploadHandle($event)">
				<button class="import-btn" @click="handleExport" v-if="perms.includes('train:question:export')">导出</button>
				<button class="template-btn" @click="handleTemplate" v-if="perms.includes('train:question:import')">模板下载</button>
			</div>
			<div class="top-right-box">
				<label class="label">关键字</label>
				<el-input class="search-input" size="small" type="text" v-model="searchKey" placeholder="请输入关键字"></el-input>
				<label class="label">教育分类</label>
				<el-select class="search-input" size="small" v-model="eduType" filterable placeholder="请选择">
					<el-option value="" label="全部"></el-option>
					<el-option label="远程" value="1"></el-option>
					<el-option label="集合" value="2"></el-option>
				</el-select>
				<label class="label">状态</label>
				<el-select class="search-input" size="small" v-model="publishFlag" filterable placeholder="请选择">
					<el-option value="" label="全部"></el-option>
					<el-option label="已发布" value="1"></el-option>
					<el-option label="待发布" value="0"></el-option>
				</el-select>
				<img class="search-btn" src="@/assets/images/sousuo.png" alt="" @click="handleSearch">
				<el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
			</div>
		</div>
		<div class="data-list-box">
			<el-table
				:data="courseList"
				:row-class-name="tableRowClassName"
				:header-cell-style="{color:'#444'}"
				:default-sort="defaultSort"
				@sort-change="handleSortChange"
				border
				style="width: 100%">
				<el-table-column
					width="50"
					label="NO.">
					<template slot-scope="scope">
						<span class="no">{{scope.$index+1}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="code"
					width="100"
					sortable="custom"
					show-overflow-tooltip
					label="课程编号">
				</el-table-column>
				<el-table-column
					prop="name"
					show-overflow-tooltip
					label="课程名称">
				</el-table-column>
				<el-table-column
					prop="courseTypeNames"
					sortable="custom"
					show-overflow-tooltip
					label="课程类型">
				</el-table-column>
				<el-table-column
					prop="rangeName"
					width="80"
					show-overflow-tooltip
					label="教育分类">
					<template slot-scope="scope">
						<span v-if="scope.row.eduType == 1">远程</span>
						<span v-if="scope.row.eduType == 2">集合</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="examinTime"
					width="80"
					show-overflow-tooltip
					label="考试时间">
				</el-table-column>
				<el-table-column
					prop="passScore"
					show-overflow-tooltip
					width="80"
					label="及格分数">
				</el-table-column>
				<el-table-column
					prop="examinAmount"
					show-overflow-tooltip
					width="80"
					label="随机题目">
				</el-table-column>
				<el-table-column
					prop="examinLimiteTimes"
					show-overflow-tooltip
					width="100"
					label="可考试次数">
				</el-table-column>
				<el-table-column
					label="状态"
					align="center"
					width="100">
					<template slot-scope="scope">
						<span v-if="scope.row.publishFlag == 1">已发布</span>
						<span v-if="scope.row.publishFlag == 0 || scope.row.publishFlag == 2">待发布</span>
					</template>
				</el-table-column>
				<el-table-column
					label="操作"
					width="90">
					<template slot-scope="scope">
						<button class="btn-blue" @click="handleEditData('check',scope.row)">查看</button>
						<button class="btn-blue" @click="handleEditData('edit',scope.row)" v-if="perms.includes('train:question:update') && scope.row.publishFlag != 1 || superAdminFlag">修改</button>
						<button class="btn-blue" @click="changeStatus(scope.row)" v-if="perms.includes('train:question:publish') && (scope.row.publishFlag == 2 || scope.row.publishFlag == 0)">发布</button>
						<button class="btn-red" @click="changeStatus(scope.row)" v-if="perms.includes('train:question:revoke') && scope.row.publishFlag == 1">撤销</button>
						<button class="btn-red" @click="handleDeleteData(scope.row)" v-if="perms.includes('train:question:delete') && scope.row.publishFlag != 1">删除</button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-wrapper pa25">
				<Pagination
					:page.sync="pageNo"
					:pageSize.sync="pageSize"
					:total="total"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange">
				</Pagination>
			</div>
		</div>
		<edit-data ref="editData" @getCourseList="getCourseList"></edit-data>
		<el-dialog
			:visible.sync="visible"
			:show-close="false"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			class="role-dialog"
			width="450px">
			<span slot="title" class="dialog-title">
				<span>提示</span>
				<img src="@/assets/images/close.png" alt="" @click="visible = false">
			</span>
			<ul class="error-list">
				<li v-for="(item,i) in errorList" :key="i">{{item}}</li>
			</ul>
		</el-dialog>
	</div>
</template>
<script>
import EditData from './components/edit-data'
import Pagination from '@/components/Pagination'
export default {
	components: {
		EditData,
		Pagination
	},
	data() {
		return {
			// 权限
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			searchKey: '',
			pageNo: 1,
			pageSize: 20,
			total: 0,
			eduType: '',//1线上2线下
			publishFlag: '',//1发布2撤销
			courseList: [],
			defaultSort: {
				prop: '',
				order: ''
			},
			deviceType: '',
			deviceTypeList: [],
			visible: false,
			errorList: [],
			superAdminFlag: JSON.parse(window.sessionStorage.userInfo).superAdminFlag == '1' ? true : false,
		}
	},
	created() {
		this.pageNo = 1;
		this.searchKey = '';
		this.getCourseList();
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({row,rowIndex}) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		// 重置查询条件
		handleReset() {
			this.searchKey = '';
			this.eduType = '';
			this.publishFlag = '';
			this.pageNo = 1;
			this.getCourseList();
		},
		handleSortChange({prop,order}) {
			this.defaultSort.prop = prop;
			this.defaultSort.order = order;
			this.$nextTick(() => {
				this.getCourseList();
			})
		},
		// 获取课程数据
		getCourseList() {
			let that = this;
			that.$request.post(
				"getCourseList",
				true,
				{
					pageNo: that.pageNo,
					pageSize: that.pageSize,
					searchKey: that.searchKey,
					eduType: that.eduType,
					publishFlag: that.publishFlag,
					prop: that.defaultSort.prop,
					order: that.defaultSort.order
				},
				function (r) {
					if (r.code == "0") {
						that.courseList = r.data.list;
						that.total = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 新增/编辑/查看
		handleEditData(type,data) {
			this.$refs.editData.init(type,data);
		},
		// 更改课程状态
		changeStatus(dt) {
			let tips = dt.publishFlag == 1 ? '是否撤销当前课程?' : '是否发布当前课程?';
			this.$confirm(tips, {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					dt.publishFlag == 1 ? 'courseInfoRevoke' : 'coursePub',
					true,
					{
						id: dt.id,
						publishFlag: dt.publishFlag == 1 ? '0' : '1'
					},
					function (r) {
						if (r.code == "0") {
							that.getCourseList();
							that.$message.success('课程状态已变更');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		// 删除
		handleDeleteData(data) {
			this.$confirm('是否删除当前课程?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'deleteCourse',
					true,
					{
						id: data.id
					},
					function (r) {
						if (r.code == "0") {
							that.getCourseList();
							that.$message.success('删除成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		handleSearch() {
			this.pageNo = 1;
			this.getCourseList();
		},
		handleSizeChange(val) {
			this.getCourseList();
		},
		handleCurrentChange(val) {
			this.getCourseList();
		},
		// 导入
		handleImport() {
			let el = document.getElementById('uploadExcel')
            el.click();
		},
		// 上传
		uploadHandle(e) {
			let file = e.target.files[0];
            if(file){
				if(window.sessionStorage.getItem('userInfo').superAdminFlag == '1' && file.size/1024/1024/1024 > 1) {
					this.$message.error('导入文件不能大于1GB');
					return
				}
				// 2022-07-31 应客户要求改为200M
				if(window.sessionStorage.getItem('userInfo').superAdminFlag != '1' && file.size/1024/1024 > 200) {
					this.$message.error('导入文件不能大于200MB');
					return
				}
				if(file.name.includes('.xlsx')){
					let formdata = new FormData();
					formdata.append('file',file);
					let that = this;
					that.$request.initUploadExcel(
						formdata,
						'courseInfoUpload',
						true
					)
					.then((data) => {
						if(data.code == 0) {
							that.pageNo = 1;
							that.getCourseList();
							if(data.data.length > 0) {
								that.visible = true;
								that.errorList = data.data;
							}else {
								that.$message.success('导入成功');
							}
						}
					})
				}else {
					this.$message.error('文件格式不正确');
				}
			}else{
				this.$message.error('请选择上传的文件');
			}
			this.$refs.uploadExcel.value = '';
		},
		// 导出
		handleExport() {
			let that = this
			that.$request.post(
				"courseInfoDownload",
				true,
				{
					searchKey: that.searchKey,
					eduType: that.eduType,
					publishFlag: that.publishFlag
				},
				function (res) {
					if (res.code == '0') {
						const linkNode = document.createElement('a');
						linkNode.style.display = 'none';
						linkNode.href = res.data;
						document.body.appendChild(linkNode);
						linkNode.click();  //模拟在按钮上的一次鼠标单击
						URL.revokeObjectURL(linkNode.href); // 释放URL 对象
						document.body.removeChild(linkNode);
					} else {
						that.$message.error(res.msg)
					}
				}
			)
		},
		// 模板下载
		handleTemplate() {
			const linkNode = document.createElement('a');
			linkNode.style.display = 'none';
			linkNode.href = '/static/template/COURSE-DATA.xlsx';
			linkNode.download = 'COURSE-DATA.xlsx';
			document.body.appendChild(linkNode);
			linkNode.click();  //模拟在按钮上的一次鼠标单击
			URL.revokeObjectURL(linkNode.href); // 释放URL 对象
			document.body.removeChild(linkNode);
		}
	}
}
</script>
<style lang="scss" scoped>
.data-content{
	.top-box{
		// height: 32px;
		margin-bottom: 20px;
	}
	.add-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.import-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.template-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.top-right-box{
		// float: right;
		text-align: right;
		margin-top: 15px;
		.label{
			font-size: 15px;
			margin-right: 5px;
		}
		.search-input,
		.search-select{
			width: 138px;
			margin: 0 10px;
		}
		.search-btn{
			width: 32px;
    		vertical-align: middle;
			cursor: pointer;
		}
		.refresh-btn{
			width: 32px;
			height: 32px;
			border: none;
			color: #117FFC;
			padding: 0;
			background-color: rgba(17, 127, 252, 0.2);
			font-weight: bold;
			font-size: 22px;
			vertical-align: bottom;
			margin-left: 5px;
		}
	}
	.btn-blue,
	.detail-btn{
		font-size: 12px;
		font-weight: bold;
		color: #1081FD;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
	.btn-red{
		font-size: 12px;
		font-weight: bold;
		color: #E9515E;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
}
.f-r{
	float: right;
}
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.error-list{
	height: 50vh;
    box-sizing: border-box;
    padding: 20px;
    overflow: auto;
	li{
		list-style: none;
	}
}
</style>

